import { Component, Injector } from '@angular/core';
import { AppInjector } from '@root/appInjector';

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent {
    constructor(private injector: Injector) {
        AppInjector.setInjector(this.injector);
    }
}
