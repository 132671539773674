import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, APP_BASE_HREF, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { RootComponent } from './root.component';

// { path: 'portal', loadChildren: () => import().then(_ => _.PortalModule) },
// { path: '', redirectTo: 'overview', pathMatch: 'full' },
// { path: '**', redirectTo: 'overview' },
const routes: Routes = [
    { path: 'app', loadChildren: () => import('@app/app.module').then(_ => _.AppModule) },
    { path: 'legal', loadChildren: () => import('@legal/legal.module').then(_ => _.LegalModule) },
    { path: '', loadChildren: () => import('@presentation/presentation.module').then(_ => _.PresentationModule) },
    { path: '**', redirectTo: '' }
];

@NgModule({
    declarations: [ RootComponent ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AngularSvgIconModule.forRoot(),
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
        })
    ],
    providers: [
        DatePipe,
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: LocationStrategy, useClass: PathLocationStrategy }
    ],
    bootstrap: [ RootComponent ]
})
export class RootModule { }
